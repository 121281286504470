<template>
  <div class=" p-0 ">
    <b-table
      v-if="organizations2 && organizations2.length > 0"
      class="table th-fit"
      :items="organizations2"
      responsive
      :fields="fields"
      :busy="busy"
      primary-key="id"
      show-empty
      sort-icon-left
      empty-text="No matching records found"
    >
      <!-- Column: name with avatar -->
      <template #cell(nameAvatar)="{ item }" style="width: : 300px;">
        <b-media class="d-flex align-items-center pl-50">
          <b-link :to="getOrganizationLocation(item)" class="text-dark d-flex align-items-center">
            <safe-img class="logo" :src="getImageResource(item.logoURL)" :placeholder="placeholder" />
            <div class="ml-1">
              <p class="font-weight-extrabold mb-0">
                {{ item.name }}
              </p>
              <!-- eslint-disable-next-line vue/no-v-html-->
              <small v-if="item.headline" class="text-muted mb-0" v-html="translatedHeadline(item.headline)" />
            </div>
          </b-link>
        </b-media>
      </template>
      <template #cell(email)="{ item }" >
        <div v-for="(email, index) in item.emails" :key="index" class="ml-1">
          <div class="mb-0">
            <p class="mb-0">
              <small v-if="email.label" class="text-muted mb-0 mr-25">{{ email.label }}</small>
              <small v-else class="text-muted mb-0 mr-25">{{ $t("backoffice.settings.email-label") }}</small>
            </p>
            <a :class="item.emails && item.emails.length > 1 ?'mb-25':''" target="_blank" :href="`mailto:${email.email}`">{{ email.email }}</a></div>
        </div>
        <p v-if="item.emails.length === 0" class="text-muted mb-0 ml-1 mt-25"> -- </p>
      </template>
      <template #cell(telfs)="{ item }" >
        <div v-for="(phone, index) in item.phones" :key="index" class="ml-1">
          <div :class="item.phones.length > 1?'mb-25':''">
            <p class="mb-0">
              <small v-if="phone.label" class="text-muted mb-0 mr-25">{{ phone.label }}</small>
              <small v-else class="text-muted mb-0 mr-25">{{ $t("backoffice.settings.phone-label") }}</small>
            </p>
            <a v-if="phone.country" :href="`tel:+${phone.country.phoneCode}${phone.phone}`">
              +{{ phone.country.phoneCode }} {{ phone.phone }}
            </a>
            <a v-if="!phone.country" :href="`tel:+${phone.phone }`">
              {{ phone.phone }}
            </a>
          </div>
        </div>
        <p v-if="item.phones.length === 0" class="text-muted mb-0 ml-1 mt-25"> -- </p>
      </template>
      <template #cell(links)="{ item }">
        <div class="ml-1 mt-25">
          <span v-for="(link, index) in item.links" :key="index">
            <a :href="toAbsoluteURL(link.url)" target="_blank" class="mr-25">
              <feather-icon
                :icon="link.label === 'Website' || link.label === 'Soundcloud' ? 'GlobeIcon' : `${link.label}Icon`"
                size="18"
                class="social-icons"
              />
            </a>
          </span>
        </div>
        <p v-if="item.links.length === 0" class="text-muted mb-0 ml-1 mt-25"> -- </p>
      </template>
      <!-- Locations -->
      <template #cell(locations)="{ item }">
        <div v-if="item.locations" class="ml-1">
          <span v-for="location in item.locations" :key="location.key">
            <p class="mb-0"><small class="text-muted mb-0">{{ location.label }}</small></p>
            {{ location.address }}
          </span>
        </div>
        <p v-if="item.locations.length === 0" class="text-muted mb-0 ml-1 mt-25"> -- </p>
      </template>
      <template #table-busy>
        <div class="text-center p-5">
          <b-spinner
            size="2rem"
            class="d-block m-auto m-1"
            variant="primary"
            label="Loading..."
          />
        </div>
      </template>
    </b-table>
    <template v-else>
      <b-row class="horizontal-placeholder w-100">
        <b-col cols="12">
          <img :src="organizationsPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('available.message', { itemName: $t('organizations.title') }) }}
          </p>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import OrganizationsPlaceholder from '@/assets/images/placeholders/light/organizations.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import AvatarPlaceholder from '@/assets/images/placeholders/light/organizations-logo.svg';
import Ripple from 'vue-ripple-directive';
import AppScroll from '@/@core/components/app-scroll/AppScroll.vue';
import { toAbsoluteURL } from '@core/utils/url-utils';
// import { checkPermissions } from "@/@core/utils/roles-utils";

export default {
  name: 'OrganizationsTable',
  components: {
    SafeImg,
    AppScroll,
  },
  directives: {
    Ripple,
  },
  props: {
    organizations: {
      type: [Array, Function],
      default: () => [],
    },
    fromDetail: {
      type: Boolean,
      default: false
    },
    busy: Boolean,
    noShowOrganizations: Boolean,
    isSearching: Boolean,
    filter: Boolean,
  },
  data() {
    return {
      organizations2: [],
      customNames: [],
    };
  },
  computed: {
    fields() {
      return [
        {
          key: 'nameAvatar',
          label: this.$t('name.title'),
          sortable: true,
        },
        {
          key: "email",
          label: this.$t("organizations.information.emails"),
          sortable: false,
          thClass: 'pl-2',
        },
        {
          key: "links",
          label: this.$t("backoffice.settings.subtitle-links"),
          sortable: false,
          thClass: 'pl-2',
        },
        {
          key: "telfs",
          label: this.$t("backoffice.settings.subtitle-phones"),
          sortable: false,
          thClass: 'pl-2',
        },
        {
          key: "locations",
          label: this.$t("backoffice.settings.subtitle-locations"),
          sortable: false,
          thClass: 'pl-2',
        },
      ];
    },
    organizationsPlaceholder() {
      return OrganizationsPlaceholder;
    },
    placeholder() {
      return AvatarPlaceholder;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    isDetailView() {
      return this.$route.name === "societies";
    },
    classifiers() {
      if (this.$store.getters.classifiers.organizations) {
        return this.$store.getters.classifiers.organizations.unpaginated;
      }
      return [];
    },
  },
  watch: {
    locale() {
      if (this.isDetailView) {
        this.createFieldsColumns();
      }
    },
    classifiers(value) {
      if (value.length > 0 && this.isDetailView) {
        this.createClassifiersColumns()
        this.createFieldsColumns()
      }
    },
  },
  created() {
    this.organizations2 = this.organizations.map(item=>item);
    if (this.isDetailView) {
      this.createClassifiersColumns()
      this.createFieldsColumns()
    }
  },
  methods: {
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        itemType: 'classifiers',
        storedKey: 'society',
        page: 1,
        requestConfig: { morphType: 'society', count: 1000 },
      });
    },
    createClassifiersColumns() {
      if (this.classifiers?.length > 0) {
        let typeNames = this.classifiers.map((item) => item.typeName);
        typeNames = [...new Set(typeNames)];

        this.dataClassifiers();
        return typeNames.map((item, index) => {
          this.fields.splice(7, 0, {
            key: item[this.locale] ? item[this.locale] : Object.values(item)[0],
            label: item[this.locale] ? (item[this.locale].length > 30 ? `${item[this.locale].substr(0, 30)}...`:  item[this.locale]) : Object.values(item)[0],
            sortable: false,
            thClass: 'pl-50',
            tdAttr: (value, key, it) => {
              let style = '';
              it.classifiers.map(x => {
                if ((x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0]) == (item[this.locale] ? item[this.locale] : Object.values(item)[0])) {
                  style = { style: `color: ${x.backgroundColor !== '#ffffff' ? x.backgroundColor : x.fontColor }; font-weight: 600` }
                }
              });
              return style;
            },
            thAttr: {
              'v-b-tooltip': 'hover',
              title: item[this.locale] ? item[this.locale] : Object.values(item)[0],
            },
            formatter: (value) => {
              const allItems = [];
              if (value && Array.isArray(value)) {
                value?.map((item) => {
                  if (typeof item === 'object') {
                    allItems.push(item[this.locale] ? item[this.locale].substr(0,30) : Object.values(item)[0]);
                  } else {
                    allItems.push(item);
                  }
                });
                return allItems.join(', ');
              }
              return value;
            },
          });
        });
      }
    },
    createFieldsColumns() {
      this.organizations2.map((item) => {
        item.custom?.map((i) => {
          const field = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.customNames.push(field);
        });
      });
      this.dataFields();
      this.customNames = [...new Set(this.customNames)];
      this.customNames.map((item, index) => {
        this.fields.splice(7, 0, {
          key: item,
          label: item.length > 30 ? `${item.substr(0,30)}...` : item,
          sortable: false,
          thClass: 'pl-50',
          thAttr: {
            'v-b-tooltip': 'hover',
            title: item,
          },
          formatter: (value) => {
            if (typeof value === 'boolean') {
              if (value === true) {
                return this.$t(
                  'organizations.edit.custom-fields.boolean-response.true',
                );
              }
              if (value === false) {
                return this.$t(
                  'organizations.edit.custom-fields.boolean-response.false',
                );
              }
            }
            return value;
          },
        });
      });
    },
    dataFields() {
      this.organizations.map((n, index) => {
        n?.custom?.map((i) => {
          const typeName = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.organizations2[index][typeName] = i.value;
        });
      });
    },
    dataClassifiers() {
      this.organizations2 = this.organizations.map((item) => item);
      this.organizations.map((n, index) => {
        n.classifiers.map((i) => {
          this.classifiers.map((x, p) => {
            if (i.key === x.key) {
              const typeName = x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0];
              if (Array.isArray(this.organizations2[index][typeName])) {
                if (this.organizations2[index][typeName].includes(i.name)) {
                  return;
                }
                this.organizations2[index][typeName].push(i.name);
              } else {
                this.organizations2[index][typeName] = [];
                this.organizations2[index][typeName].push(i.name);
              }
            }
          });
        });
      });
    },
    getOrganizationLocation(organization) {
      if(this.fromDetail){
        return null;
      }
      if (!organization.name) {
        return null;
      }
      console.log('this.$route.params', this.$route.params);
      return {
        name: 'societies-details',
        params: {
          organizationId: organization.key,
          communityId: this.$route.params.communityId,
        },
      };
    },
    translatedHeadline(headline) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, headline);
    },
    getImageResource,
    toAbsoluteURL,
  },
};
</script>
<style lang="scss" scoped>
.logo {
  object-fit: contain;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.table .th-fit {
    white-space: nowrap;
    width: 1%;
}
</style>
