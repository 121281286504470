var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" p-0 "},[(_vm.organizations2 && _vm.organizations2.length > 0)?_c('b-table',{staticClass:"table th-fit",attrs:{"items":_vm.organizations2,"responsive":"","fields":_vm.fields,"busy":_vm.busy,"primary-key":"id","show-empty":"","sort-icon-left":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(nameAvatar)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center pl-50"},[_c('b-link',{staticClass:"text-dark d-flex align-items-center",attrs:{"to":_vm.getOrganizationLocation(item)}},[_c('safe-img',{staticClass:"logo",attrs:{"src":_vm.getImageResource(item.logoURL),"placeholder":_vm.placeholder}}),_c('div',{staticClass:"ml-1"},[_c('p',{staticClass:"font-weight-extrabold mb-0"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.headline)?_c('small',{staticClass:"text-muted mb-0",domProps:{"innerHTML":_vm._s(_vm.translatedHeadline(item.headline))}}):_vm._e()])],1)],1)]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [_vm._l((item.emails),function(email,index){return _c('div',{key:index,staticClass:"ml-1"},[_c('div',{staticClass:"mb-0"},[_c('p',{staticClass:"mb-0"},[(email.label)?_c('small',{staticClass:"text-muted mb-0 mr-25"},[_vm._v(_vm._s(email.label))]):_c('small',{staticClass:"text-muted mb-0 mr-25"},[_vm._v(_vm._s(_vm.$t("backoffice.settings.email-label")))])]),_c('a',{class:item.emails && item.emails.length > 1 ?'mb-25':'',attrs:{"target":"_blank","href":("mailto:" + (email.email))}},[_vm._v(_vm._s(email.email))])])])}),(item.emails.length === 0)?_c('p',{staticClass:"text-muted mb-0 ml-1 mt-25"},[_vm._v(" -- ")]):_vm._e()]}},{key:"cell(telfs)",fn:function(ref){
var item = ref.item;
return [_vm._l((item.phones),function(phone,index){return _c('div',{key:index,staticClass:"ml-1"},[_c('div',{class:item.phones.length > 1?'mb-25':''},[_c('p',{staticClass:"mb-0"},[(phone.label)?_c('small',{staticClass:"text-muted mb-0 mr-25"},[_vm._v(_vm._s(phone.label))]):_c('small',{staticClass:"text-muted mb-0 mr-25"},[_vm._v(_vm._s(_vm.$t("backoffice.settings.phone-label")))])]),(phone.country)?_c('a',{attrs:{"href":("tel:+" + (phone.country.phoneCode) + (phone.phone))}},[_vm._v(" +"+_vm._s(phone.country.phoneCode)+" "+_vm._s(phone.phone)+" ")]):_vm._e(),(!phone.country)?_c('a',{attrs:{"href":("tel:+" + (phone.phone))}},[_vm._v(" "+_vm._s(phone.phone)+" ")]):_vm._e()])])}),(item.phones.length === 0)?_c('p',{staticClass:"text-muted mb-0 ml-1 mt-25"},[_vm._v(" -- ")]):_vm._e()]}},{key:"cell(links)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ml-1 mt-25"},_vm._l((item.links),function(link,index){return _c('span',{key:index},[_c('a',{staticClass:"mr-25",attrs:{"href":_vm.toAbsoluteURL(link.url),"target":"_blank"}},[_c('feather-icon',{staticClass:"social-icons",attrs:{"icon":link.label === 'Website' || link.label === 'Soundcloud' ? 'GlobeIcon' : ((link.label) + "Icon"),"size":"18"}})],1)])}),0),(item.links.length === 0)?_c('p',{staticClass:"text-muted mb-0 ml-1 mt-25"},[_vm._v(" -- ")]):_vm._e()]}},{key:"cell(locations)",fn:function(ref){
var item = ref.item;
return [(item.locations)?_c('div',{staticClass:"ml-1"},_vm._l((item.locations),function(location){return _c('span',{key:location.key},[_c('p',{staticClass:"mb-0"},[_c('small',{staticClass:"text-muted mb-0"},[_vm._v(_vm._s(location.label))])]),_vm._v(" "+_vm._s(location.address)+" ")])}),0):_vm._e(),(item.locations.length === 0)?_c('p',{staticClass:"text-muted mb-0 ml-1 mt-25"},[_vm._v(" -- ")]):_vm._e()]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center p-5"},[_c('b-spinner',{staticClass:"d-block m-auto m-1",attrs:{"size":"2rem","variant":"primary","label":"Loading..."}})],1)]},proxy:true}],null,false,3936523433)}):[_c('b-row',{staticClass:"horizontal-placeholder w-100"},[_c('b-col',{attrs:{"cols":"12"}},[_c('img',{attrs:{"src":_vm.organizationsPlaceholder}})]),_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t('available.message', { itemName: _vm.$t('organizations.title') }))+" ")])])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }